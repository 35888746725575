<template>
    <div class="seckillUpgradePrice">
       <iframe scrolling: no
        :src="src"
        frameborder="0"
      ></iframe>
    </div>
  </template>
  <script>
  import urls from "../../../utils/baseUrl2";
  export default {
    name: "seckillUpgradePrice",
    props: {
      id: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        src: `${urls.baseUrl1}giftUpgradePrice/roomPriceIframe.html?upgradeId=${this.id}&isDevelopment=${urls.baseUrl}`,
      };
    },
  };
  </script>
  <style lang="less" scoped>
  .seckillUpgradePrice {
    height: 860px;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  </style>